<template>
  <div ref="chat-section">
    <BaseLoading style="min-height: 400px" v-if="loading_mgs"></BaseLoading>
    <div v-else>
      <div class="" v-if="order && Object.keys(order).length > 0">
        <div class="card-header header-elements-inline">
          <!-- <h6 class="card-title font-weight-bold text-uppercase px-0 pt-1">
            Order ID:
            <router-link :to="{ name: 'Order', params: { id: order.id } }">#{{ order.id || "" }}</router-link>
          </h6> -->
          <div class="header-elements">
            <div class="list-icons">
              <!-- <a class="list-icons-ite m" data-action="reload"></a> -->
            </div>
          </div>
        </div>
        <div class="alert alert-success border border-success rounded-0 alert-dismissible mb-1">
          <button type="button" class="close" data-dismiss="alert">
            <span>×</span>
          </button>
          <span class="font-weight-bold">Note!</span> Simply Type in your
          message and click on send. The message will be received by the
          assigned writer and support team.
          <a href="#" class="alert-link">try submitting again</a>.
        </div>
        <div class="alert alert-danger my-3   border border-danger rounded-0 alert-dismissible">
          <button type="button" class="close" data-dismiss="alert">
            <span>×</span>
          </button>
          Your personal information (Phone Number, Email Address) is always
          kept private and we won't share it–just don't include it in your
          order details.
        </div>

        <div class="card-body px-1">
          <ul v-if="orderMessages && orderMessages.length > 0" class="media-list media-chat chat-box media-chat-scrollable mb-3">
            <span v-if="orderMessages && orderMessages.length > 0">
              <span v-for="(message, index) in orderMessages" :key="index + 'msgs'">
                <li v-if="!parseInt(message.is_sender)" class="media">
                  <div class="mr-3">
                    <a href="../../../assets/images/supportIcon.png">
                      <img src="../../../assets/images/supportIcon.png" class="rounded-circle" alt="" width="40" height="40" />
                    </a>
                  </div>

                  <div class="media-body mb-2">
                    <div class="media-chat-item">
                      <p class="font-weight-bold mb-0 text-primary">
                        {{ message.user_type || "" }}
                      </p>
                      <pre v-html="message.message || ''"></pre>
                      <div class="font-size-sm text-muted mt-2">
                        {{ message.create_stamp || "" }}
                      </div>
                    </div>
                    <button v-if="userType == userTypes.admin" @click="deleteMessage(message?.id)" class="m-1"> 
                      <svg fill="#ef5350" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 290 290" xml:space="preserve">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                      <g id="SVGRepo_iconCarrier"> <g id="XMLID_24_"> <g id="XMLID_29_"> <path d="M265,60h-30h-15V15c0-8.284-6.716-15-15-15H85c-8.284,0-15,6.716-15,15v45H55H25c-8.284,0-15,6.716-15,15s6.716,15,15,15 h5.215H40h210h9.166H265c8.284,0,15-6.716,15-15S273.284,60,265,60z M190,60h-15h-60h-15V30h90V60z"/> </g> <g id="XMLID_86_"> <path d="M40,275c0,8.284,6.716,15,15,15h180c8.284,0,15-6.716,15-15V120H40V275z"/> </g> </g> </g>
                      </svg>
                    </button>
                  </div>
                </li>

                <li v-else class="media media-chat-item-reverse my-3 ">
                  <div class="media-body">
                    <button v-if="userType == userTypes.admin" @click="deleteMessage(message?.id)" class="m-1"> 
                      <svg fill="#ef5350" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 290 290" xml:space="preserve">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                      <g id="SVGRepo_iconCarrier"> <g id="XMLID_24_"> <g id="XMLID_29_"> <path d="M265,60h-30h-15V15c0-8.284-6.716-15-15-15H85c-8.284,0-15,6.716-15,15v45H55H25c-8.284,0-15,6.716-15,15s6.716,15,15,15 h5.215H40h210h9.166H265c8.284,0,15-6.716,15-15S273.284,60,265,60z M190,60h-15h-60h-15V30h90V60z"/> </g> <g id="XMLID_86_"> <path d="M40,275c0,8.284,6.716,15,15,15h180c8.284,0,15-6.716,15-15V120H40V275z"/> </g> </g> </g>
                      </svg>
                    </button>
                    
                    <div class="media-chat-item p-2  ">
                      <p class="font-weight-bold mb-2 text-light">
                        {{ message.user_type || "" }}
                      </p>
                      <pre class="text-light" v-html="message.message || ''"></pre>
                      <div class="font-size-xs text-right text-white mt-2">
                      {{ message.create_stamp || "" }}
                    </div>
                    </div>

                  </div> 

                  <div class="ml-3">
                    <a href="../../../assets/images/supportIcon.png">
                      <img src="../../../assets/images/supportIcon.png" class="rounded-circle" alt="" width="40" height="40" />
                    </a>
                  </div>
                </li>
              </span>
            </span>
            <!-- notes -->
            <!-- <v-alert dense  type="primary ">
              <small>
                Simply Type in your message and click on send. The message will
                be received by the assigned writer and support team.
              </small>
            </v-alert>
            <v-alert dense  type="error">
              <small>Your personal information (Phone Number, Email Address) is
                always kept private and we won't share it–just don't include it
                in your order details.</small>
            </v-alert> -->
            
            <!-- /notes -->
          </ul>
          <div  class="d-flex flex-column chat-box mb-4 align-items-center justify-content-center "  v-else>
        
              <strong class="custom_imageEmpty  text-center" >No messages yet.</strong>
          
        </div>

          <form  >
            <div class="d-flex position-relative "> 
            <textarea name="enter-message" class="form-control mb-3" rows="3" cols="1" v-model="messageForm.message"
              required placeholder="Enter your message..." v-if="user.id != '5986'"></textarea>
              <emoji-picker class="d-flex" @emoji="append" :search="search">
              <span
                class="emoji-invoker  mx-2"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent">
              
              <svg class="mx-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path id="vac-icon-emoji" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path><!----></svg>
              </span>
              <div slot="emoji-picker" class="" slot-scope="{ emojis, insert, display }">
                <div class="emoji-picker" style="
                top: 40px;
                right: 0;
                position: absolute;
                ">
                  <div class="emoji-picker__search">
                    <input type="text" v-model="search" v-focus>
                  </div>
                  <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                        >{{ emoji }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
             

            </div>

            <!-- <label class="text-danger"> Select a reciepient </label> -->

            

            <div class="d-flex mt-0 content-center" style="align-items: center">
              <button
              type="submit"
              v-if="order.client_id "
              @click.prevent="confirmDialog = true; receiver = 'client'; messageForm.receiver_id = order.client_id "
              class="btn btn-lg  rounded-0 w-20 h-50 btn-primary px-4   "
              :disabled="messageForm.message === '' "
            >

              Send to Client
            </button>

            <button
              type="submit"
              v-if="order.writer_id && userType != userTypes.writer  "
              @click.prevent="confirmDialog = true; receiver = 'writer'; messageForm.receiver_id = order.writer_id "
              class="btn btn-lg ml-3 rounded-0 w-20 h-50 mx-2 btn-primary px-4   "
              :disabled="messageForm.message === '' "
            >

              Send to Writer
            </button>

            <button
              type="submit"
              v-if="order.writer && order.writer.editor_id  && userType != userTypes.editor "
              @click.prevent="confirmDialog = true; receiver = 'editor'; messageForm.receiver_id = order.editor_id "
              class="btn btn-lg ml-3 rounded-0 w-20 h-50 mx-2 btn-primary  px-4   "
              :disabled="messageForm.message === '' "
            >

              Send to Editor
            </button>

            <button
              type="submit"
              v-if="userType != userTypes.admin "
              @click.prevent="confirmDialog = true; receiver = 'support'; messageForm.receiver_id = '-1' "
              class="btn btn-lg ml-3 rounded-0 w-20 h-50 mx-2 btn-primary px-4   "
              :disabled="messageForm.message === '' "
            >

              Send to Support
            </button>


              <!-- <div class="form-check" v-if="order.client_id &&
              user.id != '5986'">
                <input v-model="messageForm.receiver_id" class="form-check-input" type="radio" name="exampleRadios"
                  id="exampleRadios2" :value="order.client_id" @click="receiver = 'client'" />
                <label class="form-check-label" for="exampleRadios2">
                  Client
                </label>
              </div> -->

              <!-- {{receiver}}
              <div class="mx-2"></div>
              <div class="form-check" v-if="order.writer_id && userType != userTypes.writer &&
              user.id != '5986'">
                <input v-model="messageForm.receiver_id" class="form-check-input" type="radio" name="exampleRadios"
                  id="exampleRadios2" :value="order.writer_id" @click="receiver = 'writer'" />
                <label class="form-check-label" for="exampleRadios2">
                  Writer
                </label>
              </div> -->

              <!-- <div class="d-flex" v-if="userType == userTypes.writer">
                <div class="form-check" v-if="order.writer && order.writer.editor_id &&
                user.id != '5986'">
                  <input v-model="messageForm.receiver_id" class="form-check-input" type="radio" name="exampleRadios"
                    id="exampleRadios2" :value="order.writer.editor_id" @click="receiver = 'editor'" />
                  <label class="form-check-label" for="exampleRadios2">
                    Editor
                  </label>
                </div>

                <div class="form-check ml-2">
                  <input v-model="messageForm.receiver_id" class="form-check-input" type="radio" name="exampleRadios"
                    id="exampleRadios2" :value="-1" @click="receiver = 'support'" />
                  <label class="form-check-label" for="exampleRadios2">
                    Support
                  </label>
                </div>
              </div> -->

              <!-- <div class="form-check ml-2 mr-0" v-if="
                userType == userTypes.admin &&
                order.writer &&
                order.writer.editor_id &&
                user.id != '5986'
              ">
                <input v-model="messageForm.receiver_id" class="form-check-input" type="radio" name="exampleRadios"
                  id="exampleRadios2" :value="order.writer.editor_id" @click="receiver = 'editor'" />
                <label class="form-check-label" for="exampleRadios2">
                  Editor
                </label>
              </div> -->

              <!-- <button v-if="user.id != '5986'" type="button"
                class="btn bg-primary-400 btn-labeled btn-labeled-right ml-auto" @click.stop="confirmDialog = true"
                :disabled="messageForm.busy">
                <b><i class="icon-paperplane"></i></b> Send
              </button> -->
              <!-- <input type="checkbox" name="checktest " v-model="messageForm.check" id="">

              {{messageForm.check}} -->
              
            </div>

            <v-dialog v-model="confirmDialog" max-width="290">
              <v-card>
                <v-card-title class="text-base"> Confirm </v-card-title>

                <v-card-text class="text-left">
                  <span v-if="!!receiver">
                    Are you sure you want to send this message to
                    <strong>{{ receiver }}</strong>
                  </span>
                  <span v-else> Kindly select a reciepient </span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="error darken-1" text @click="confirmDialog = false">
                    Close
                  </v-btn>

                  <v-btn v-if="!!receiver" color="primary darken-1" text @click="sendMessage">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </form>
        </div>
      </div>

      <div v-else class="card-body">
        <h5 class="py-4 mx-2">Select a conversation to start chatting</h5>
      </div>
    </div>
    <BaseOverlay v-if="sending"></BaseOverlay>
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
// import 'emoji-picker-element';
// import data from "emoji-mart-vue-fast/data/all.json";
// import "emoji-mart-vue-fast/css/emoji-mart.css";
// import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import EmojiPicker from 'vue-emoji-picker'
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    EmojiPicker
  },
  data() {
    return {
      chatHeight: 0,
      loading_mgs: false,
      sending: false,
      check:"true",
      receiver: "",
      confirmDialog: false,
    };
  },
  computed: {
    ...mapState("messages", ["orderMessages", "messageForm"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("auth", ["user"])
  },
  async mounted() {
    this.loading_mgs = true;
    await this.getMessages()
      .then((res) => {
        console.log("Messages res ", res);
      })
      .catch((err) => {
        console.log("Messages err ", err);
      })
      .finally(() => {
        this.loading_mgs = false;
      });
  },

  methods: {
    ...mapActions("messages", ["_getMessages", "_sendMessage", "_deleteMessage"]),
    ...mapMutations("messages", ["setMessages"]),

    insert(emoji) {
      this.messageForm.message += emoji
    },

    append(emoji) {
      this.messageForm.message += emoji
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    addEmoji(emoji) {
      this.messageForm.message += emoji.emoji;
      this.showEmojiPicker = false; // Close the emoji picker after selection
    },

    scrollToBottom() {
      var element = this.$refs["inout-section"];
      element.scrollTop = element.scrollHeight;
      console.log("this.chatHeight", element.scrollHeight);
    },

    async getMessages() {
      try {
        console.log("this.$route.params.id", this.$route.params.id);
        await this._getMessages(this.$route.params.id || this.order.id);
      } catch (error) {
        this.loading_mgs = false;
      }
    },
    sendMessage() {
      this.sending = true;
      this.confirmDialog = false;
      this._sendMessage({
        messageForm: this.messageForm,
        order_id: this.order.id,
        check:this.check
      })
        .then((res) => {
          console.log(res);
          this.messageForm.reset();
          // success
        })
        .catch((err) => {
          console.log(err);
          // handle error
        })
        .finally(() => {
          this.sending = false;
          this.scrollToBottom();
        });
    },

    deleteMessage(event) {
      this.sending = true;
      this._deleteMessage({
        order_id: this.order.id,
        id:event
      })
        .then((res) => {
          console.log(res);
          // success
        })
        .catch((err) => {
          console.log(err);
          // handle error
        })
        .finally(() => {
          this.sending = false;
          this.scrollToBottom();
        });
    },
  },
  beforeDestroy() {
    this.setMessages([]);
  },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: 800px) {
  .is-placeholder {
    min-height: 140px;
  }

  .is-chat-section {
    min-height: 7 8vh !important;
  }

  .stick-message-form {
    position: sticky;
    bottom: 5px;
  }

  .fix-message-form {
    margin-top: 20px;
    position: absolute;
    bottom: -10px;
    left: 20px;
    right: 34px;
  }
}

.is-chat-section {
  max-height: 78vh;
  min-height: 40vh;
  overflow-y: scroll;

  .media {
    width: 80%;

    &.is-out {
      .media-body {
        background: #dcf8c6;
      }
    }

    .media-body {
      box-shadow: 0 2px 5px #ccc;
    }
  }
}

@media screen and (min-width: 800px) {
  .is-chat-section {
    min-height: 85vh !important;
  }

  .stick-message-form {
    position: sticky;
    bottom: 5px;
    // position: absolute;
    // bottom: -10px;
    margin-bottom: 20px;
    // left: 10px;
    // right: 30px;
  }
}

pre {
  padding: 0 !important;
  border: none;
}
</style>